import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import GUI from 'lil-gui'

// const gui = new GUI()

const canvas = document.querySelector("canvas")

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
let aspectRatio = sizes.width / sizes.height

/* ------------------------------------------- */
/* SCENE */
/* ------------------------------------------- */

const scene = new THREE.Scene()

/* ------------------------------------------- */
/* LIGHTS */
/* ------------------------------------------- */

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 0.5)
pointLight.position.x = 0
pointLight.position.y = 0
pointLight.position.z = 0
scene.add(pointLight)

/* ------------------------------------------- */
/* TEXTURES */
/* ------------------------------------------- */

const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcap.png')

/* ------------------------------------------- */
/* CAMERA */
/* ------------------------------------------- */

const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 100)
camera.position.z = 8
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/* ------------------------------------------- */
/* OBJECTS */
/* ------------------------------------------- */

/* Logo */
const gltfLoader = new GLTFLoader()

gltfLoader.load(
    '/models/logo.gltf',
    (gltf) => {
        gltf.scene.children[0].material = new THREE.MeshMatcapMaterial()
        gltf.scene.children[0].material.matcap = matcapTexture
        scene.add(gltf.scene)

        /* Object */
        const geometry = new THREE.SphereGeometry(1,1,1)
        const material = new THREE.MeshMatcapMaterial()
        material.matcap = matcapTexture
        for(let i = 0; i < 1000; i++){
            const mesh = new THREE.Mesh(geometry, material)
            mesh.position.x = (Math.random() - 0.5) * 100
            mesh.position.y = (Math.random() - 0.5) * 100
            mesh.position.z = (Math.random() - 0.5) * 100
            const randomScale = Math.random()
            mesh.scale.set(randomScale, randomScale, randomScale)
            mesh.rotation.x = (Math.PI * Math.random())
            mesh.rotation.y = (Math.PI * Math.random())
            scene.add(mesh)
        }
    }
)

/* ------------------------------------------- */
/* RENDERER */
/* ------------------------------------------- */

const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/* ------------------------------------------- */
/* ANIMATION */
/* ------------------------------------------- */

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    aspectRatio = sizes.width / sizes.height

	camera.aspect = sizes.width / sizes.height
	camera.updateProjectionMatrix()
	
	renderer.setSize(sizes.width, sizes.height)
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

const clock = new THREE.Clock()
const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    if(scene.children[3]){
        scene.children[3].rotation.y = (elapsedTime * Math.PI) * 0.3
    }

    controls.update()
    renderer.setSize(sizes.width, sizes.height)
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}

tick()
