const burger = document.querySelector(".burger")
const ul = document.querySelector(".network ul")

const responsiveValue = 600
const responsive = (value) => {
    if(window.innerWidth <= value){
        burger.style.display = 'flex'
        burger.classList.remove('show')
        ul.classList.remove('show2')
    } else if(window.innerWidth > value){
        burger.style.display = 'none'
    }
}

window.addEventListener('load', () => {
    responsive(responsiveValue)
})

window.addEventListener('resize', () => {
    responsive(responsiveValue)
})

burger.addEventListener('click', () => {
    burger.classList.toggle('show')
    ul.classList.toggle('show2')
})